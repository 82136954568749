.recpt-wrapper {
    width: 600px;
    height: 250px;
}

.first-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.receipt-text {
    display: flex;
    justify-content: space-between;

}

.receipt-text h2 {
    font-size: 8px;
}

.receipt-text h3 {
    font-size: 8px;
    font-weight: bold;
}

.receipt-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.receipt-image img {
    width: 50px;
    border: 10px double rgb(255, 236, 66);
    border-radius: 50%;
}

.name-title {
    font-size: 10px;
    font-weight: 900;
    text-align: center;
    font-weight: bolder;
    color: var(--yellow)
}

.recpt-title {
    margin: 0;
    font-size: 8px;
    font-weight: bolder;
    text-align: center;
    color: var(--white)
}

.top-title {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 2px;
    font-size: 10px;
    font-weight: 600;
    line-height: inherit;
    color: var(--green);
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}

.info-details {
    display: flex;
    justify-content: space-between;
    padding: 2px;
    margin-bottom: 5px;
}

.info-text>div {
    font-size: 8px;
}

.payment-details {
    display: flex;
    justify-content: space-between;
    padding: 2px;
}

.payment-text>div {
    font-size: 8px;
}

.receipt-signature {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.preview-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
}

.receipt-signature img{
    width: 20%;
    
}