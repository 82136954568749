/* Logo Login */
.receipt-preview {
    margin-top: 10px;
    background-color: var(--white);
    border-radius: 15px;
    padding: 20px;
}

.image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-container img {
    width: 90px;
    height: 90px;
    position: relative;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    border: 10px double rgb(255, 236, 66);
    border-radius: 50%;
}

.image-container h3 {
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    color: var(--yellow)
}

.image-container h4 {
    font-size: 14px;
    font-weight: bolder;
    text-align: center;
    color: var(--white)
}

.top-container {
    display: flex;
    justify-content: space-between;
}

.info-container {
    display: flex;
    justify-content: space-between;
    background-color: var(--white);
    box-shadow: inset 0 0 0 1000px rgba(206, 205, 205, 0.2);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.info-container h3 {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 600;
    line-height: inherit;
    color: var(--green);
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}

.info-container h4 {
    font-size: 12px;

}

.payment-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 10px;
    border-radius: 10px;
    margin-top: 20px; */
}

.payment-container h3 {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 600;
    line-height: inherit;
    background-color: var(--green);
    color: var(--white);
    border: 0;
    border-bottom: 1px solid #e5e5e5;

}

.pay-info {
    display: flex;
    justify-content: space-between;
}

.pay-info h4 {
    font-size: 14px;
}

.preview-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}