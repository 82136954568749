.confirm-application {
    background-color: bisque;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 20px;
}

.confirm-heading {
    font-size: 24px;
}


.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

.checkbox>span {
    color: #34495E;
    padding: 0.25rem 0.25rem;
}

.checkbox>input {
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #34495E;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    transition-property: all;
    background-color: teal;
    cursor: pointer;
}


input[type="radio"] {
    position: absolute;
    appearance: none;
    border: 1px solid #d3d3d3;
    width: 20px;
    height: 20px;
    content: none;
    outline: none;
    margin: 0;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

input[type="radio"]:checked {
    appearance: none;
    outline: none;
    padding: 0;
    content: none;
    border: none;
}

input[type="radio"]:checked::before {
    position: absolute;
    color: green !important;
    content: "\00A0\2713\00A0" !important;
    border: 1px solid #d3d3d3;
    font-weight: bolder;
    font-size: 21px;
}