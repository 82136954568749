:root {
  --main-color: #266928;
  --brown: #073b1e;
  --red: red;
  /* --brown: brown; */
  --black: #000;
  --light-black: #aaa;
  --green: #25A560;
  --yellow: #f8ed4d;
  --orange: #f39c12;
  --light-color: #888;
  --light-bg: rgb(255, 255, 255);
  --light-blue: rgba(172, 225, 255, 0.945);
  --blue: #001a9b;
  --blue-black: #000b41;
  --white: white;
  --smoke: whitesmoke;
  --plain-color: rgba(217, 221, 146, 0.2);
  --border: 2px solid var(--light-color);
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'PT Sans', sans-serif; */
  font-family: 'Roboto', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/public/images/others/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/public/images/others/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/public/images/others/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.confirm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  --color: hsl(var(--primary-dark-hsl), .7);
  background-color: var(--white);
  box-shadow: 0 0 15px 0 var(--color);
  padding: 20px 20px;
  min-width: 400px;
  border-radius: 10px;
}

.view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: hsl(var(--primary-dark-hsl), .7);
  background-color: var(--white);
  box-shadow: 0 0 15px 0 var(--color);
  padding: 10px 20px;
  min-width: 600px;
  border-radius: 10px;
}

.confirm-container h3 {
  padding: 0 10px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: var(--main-color);
}

.confirm-container p {
  padding: 0 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.confirm-buttons>button {
  font-weight: 600;
  width: 100px;
}

.col-10 {
  float: left;
  width: 10%;
  margin-top: 6px;
}

.col-20 {
  float: left;
  width: 20%;
  margin-top: 6px;
  margin-right: 30px;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
  margin-right: 30px;
}

.col-30 {
  float: left;
  width: 30%;
  margin-top: 6px;
  margin-right: 5%;
}

.col-35 {
  float: left;
  width: 30%;
  margin-top: 6px;
  margin-right: 100px;
}

.col-40 {
  float: left;
  width: 40%;
  margin-top: 6px;
  /* margin-right: 30px; */
}

.col-50 {
  float: left;
  width: 50%;
  margin-top: 6px;
  margin-right: 30px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
  margin-right: 30px;
}



/* Clear floats after the columns */
.row::after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 750px) {

  .col-10,
  .col-20,
  .col-25,
  .col-30,
  .col-35,
  .col-40,
  .col-50,
  .col-75,
  input[type=submit] {
    width: 100%;
    margin-top: 0;
  }

  .flex-col {
    flex-direction: column;
    /* flex-flow: column nowrap; */
  }

  .button-container {
    flex-direction: column;
  }
}

.table>tbody>tr>td>div>a {
  color: var(--main-color);
  font-size: 16px;
}

.table>tbody>tr>td>div>a:hover,
.table>tbody>tr>td>div>a:active {
  color: var(--blue)
}

.name-logo {
  display: flex;
  text-align: start;
  justify-content: flex-start;
  position: relative;
  padding: 1rem 1.5rem;
  /* margin-right: auto; */
  /* margin-left: 0.2rem; */
}

.name-logo>h4 {
  font-size: 24px;
  font-weight: bold;
  color: var(--yellow);
}

.name-logo img {
  width: 40px;
  border-radius: 50%;
}


.custom-ui {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: hsl(var(--primary-dark-hsl), .7);
  background-color: var(--white);
  box-shadow: 0 0 15px 0 var(--black);
  padding: 10px 20px;
  min-width: 600px;
  border-radius: 10px;
}

form label span {
  color: var(--red);
}

.photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* row-gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem; */
}

.photo img {
  width: 10%;
  border-radius: 10px;
}

.tenant-code {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
}

.tenant-code h1 {
  font-size: 18px;
  font-weight: bold;
  color: var(--main-color);
}

.tenant-code h2 {
  font-size: 18px;
  font-weight: bold;
  color: brown;
}

.tenant-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: 10px;
}

.tenant-photo img {
  width: 10%;
  border-radius: 10px;
}

.registration-ui {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background-color: var(--black);
  box-shadow: 0 0 15px 0 var(--black);
  padding: 10px 20px;
  width: 100%;
  min-width: 1200px;
  height: 70vh;
  border-radius: 10px;
  margin-top: 180px;
  overflow-x: hidden;
  overflow-y: auto;
}

.input-info {
  margin: 0;
  color: var(--red);
  font-size: 12px;
  font-weight: bold;
}


/* NEW */
.remove-button {
  display: none;
}

.offscreen {
  position: absolute;
  left: -10000px;
}

.split-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  gap: 1rem;
}

.flex-col {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.35rem;
  width: 100%;
}

.button-container {
  display: flex;
  gap: 1.5rem;
}

.location-view {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  padding: 20px;
}

.a-link {
  margin: 0;
  color: var(--blue-black);
  font-size: 12px;
  font-weight: bold;
}

.a-link:hover,
.a-link:active {
  color: var(--green)
}

.rate-info {
  color: var(--red)
}

.gps-location {
  display: flex;
  gap: 2rem;
}

.confirm-payment {
  width: 100%;
  min-width: 600px;
  background-color: var(--main-color);
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.confirm-payment .title {
  padding: 10px;
  text-align: center;
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.details {
  padding: 30px;
  background-color: black;
}

.details .name {
  margin-left: 30px;
  padding: 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.details .value {
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.toast-message {
  background: white;
  color: black;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  width: 34vw;
}