.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
}

.location-map {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    height: 200px;
}
.location-map .google-map {
    width: 50%;
    height: 60vh;
}

.pin {
    display: flex;
    align-items: center;
    width: 180px;
}

.location-pin-icon {
    font-size: 5vh;
    color: var(--green);
}
.satellite-pin-icon {
    font-size: 5vh;
    color: var(--red);
}

.location-pin-text {
    font-size: 1.3em;
    font-weight: bold;
    color: var(--blue-black);
}
.satellite-pin-text {
    font-size: 1.3em;
    font-weight: bold;
    color: var(--white);
}

@media screen and (min-width: 799px) {
    .google-map {
        height: 50vh;
    }

    .map-h2 {
        font-size: 1.3rem;
        font-weight: 400;
    }

    .pin {
        width: 15vw;
    }

    .pin-icon {
        font-size: 3vw;
    }
}